import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  isLoading: false,
  data: {},
  errors: [],
};

// Thunks
export const getSuggested = createAsyncThunk(
  "products/getSuggested",
  async (args, thunkApi) => {
    try {
      const { page, size = 10, query, filter = JSON.stringify({}) } = args;
      const { data } = await axios.get(
        `/api/suggestions?page=${page}&size=${size}&query=${query}&filter=${JSON.stringify(
          filter
        )}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createSuggested = createAsyncThunk(
  "products/createSuggested",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/suggestions", args, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateSuggested = createAsyncThunk(
  "products/updateSuggested",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.patch(
        `/api/suggestions/${args.id}`,
        args.values,
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "Something went wrong" }] }
          : error.response.data
      );
    }
  }
);

// Slice
const suggestedSlice = createSlice({
  name: "suggested",
  initialState,
  extraReducers: (builder) => {
    builder
      // Get suggested products
      .addCase(getSuggested.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getSuggested.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getSuggested.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors || [];
      })

      // Create suggested product
      .addCase(createSuggested.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createSuggested.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.data.data?.length < 10) {
          state.data.data = [...(state.data.data || []), action.payload.data];
        }
        state.data.itemsCount = (state.data.itemsCount || 0) + 1;
        state.errors = [];
      })
      .addCase(createSuggested.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      })

      // Update suggested product
      .addCase(updateSuggested.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateSuggested.fulfilled, (state, action) => {
        state.isLoading = false;
        const index = state.data.data?.findIndex(
          (el) => el._id === action.payload.data._id
        );
        if (index !== -1) {
          state.data.data[index] = action.payload.data;
        }
        state.errors = [];
      })
      .addCase(updateSuggested.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors || [];
      });
  },
});

export default suggestedSlice.reducer;
