import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Stack,
  Text,
  Button,
  Input,
  Select,
  SimpleGrid,
  Flex,
} from "@chakra-ui/react";

import FilterModal from "../../../shared/modal/FilterModal";
import Search from "../../../shared/search/Search";

import { getUsers } from "../../../../store/users/usersSlice";

import theme from "../../../global/theme";

const Filter = ({ handleFilter, handleClearFilter, disablesBtns }) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    getValues,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);

  watch(["target", "admin"]);

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filter] = useState(JSON.stringify({}));

  useEffect(() => {
    dispatch(getUsers({ page, query, filter }));
  }, [dispatch, page, query, filter]);

  return (
    // <FilterModal>
    <form
      onSubmit={handleSubmit((values) => {
        let finalValues = {};
        console.log(values);
        for (let prop in values) {
          if (values[prop]) {
            if (prop === "target" || prop === "admin") {
              finalValues[prop] = values[prop]?._id;
            } else if (prop === "from" || prop === "to") {
              finalValues[prop] = new Date(values[prop]);
            } else {
              finalValues[prop] = values[prop];
            }
          }
        }
        handleFilter(finalValues);
      })}
    >
      <SimpleGrid columns={{ base: 2, md: 3, lg: 5 }} spacing={6}>
        <FormControl>
          <FormLabel fontWeight="bold" textTransform="capitalize" color="black">
            {t("general.from")}
          </FormLabel>
          <Input
            type="date"
            border="none"
            bg={theme.light}
            color={theme.dark}
            borderRadius={4}
            placeholder={t("general.from")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("from")}
          />
          {errors.from?.message && (
            <Text color="red.600" marginTop={2}>
              {errors.from.message}
            </Text>
          )}
        </FormControl>

        <FormControl>
          <FormLabel fontWeight="bold" textTransform="capitalize" color="black">
            {t("general.to")}
          </FormLabel>
          <Input
            type="date"
            bg={theme.light}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.to")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("to")}
          />
          {errors.to?.message && (
            <Text color="red.600" marginTop={2}>
              {errors.to.message}
            </Text>
          )}
        </FormControl>

        {auth.user?.role === "admin" && (
          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="capitalize"
              color="black"
            >
              الأدمن
            </FormLabel>
            <Search
              page={page}
              itemsCount={users.data?.itemsCount}
              data={users.data?.data}
              prop="name"
              currentElement={getValues("admin")}
              handleChangePage={(page) => {
                setPage(page);
              }}
              handleChangeQuery={(query) => {
                setQuery(query);
              }}
              handleSelected={(item) => {
                setValue("admin", item);
              }}
            />
            {errors.admin?.message && (
              <Text color="red.600" marginTop={2}>
                {errors.admin.message}
              </Text>
            )}
          </FormControl>
        )}

        <FormControl>
          <FormLabel fontWeight="bold" textTransform="capitalize" color="black">
            حالة الطلب
          </FormLabel>
          <Select bg={theme.light} color={theme.dark} {...register("status")}>
            <option value="">الجميع</option>
            <option value="pending">جار المراجعة</option>
            <option value="approved">تمت الموافقه</option>
          </Select>
        </FormControl>
        {auth.user?.role === "admin" && (
          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="capitalize"
              color="black"
            >
              المستخدم
            </FormLabel>
            <Search
              page={page}
              itemsCount={users.data?.itemsCount}
              data={users.data?.data}
              prop="name"
              currentElement={getValues("target")}
              handleChangePage={(page) => {
                setPage(page);
              }}
              handleChangeQuery={(query) => {
                setQuery(query);
              }}
              handleSelected={(item) => {
                setValue("target", item);
              }}
            />
            {errors.target?.message && (
              <Text color="red.600" marginTop={2}>
                {errors.target.message}
              </Text>
            )}
          </FormControl>
        )}
        {auth.user?.role === "admin" && (
          <FormControl>
            <FormLabel
              fontWeight="bold"
              textTransform="capitalize"
              color="black"
            >
              نوع المستخدم
            </FormLabel>
            <Select bg={theme.light} color={theme.dark} {...register("type")}>
              <option value="">الجميع</option>
              <option value="vendor">تاجر</option>
              <option value="sales">مسوق</option>
            </Select>
          </FormControl>
        )}

        <Flex justifyContent="center" alignItems="center" gap={4}>
          <Button
            type="submit"
            isLoading={disablesBtns}
            mt={8}
            width="40%"
            colorScheme="blue"
          >
            {t("general.save")}
          </Button>
          <Button
            type="button"
            isLoading={disablesBtns}
            mt={8}
            width="40%"
            colorScheme="red"
            onClick={() => {
              handleClearFilter();
              reset();
            }}
          >
            {t("general.reset")}
          </Button>
        </Flex>
      </SimpleGrid>
    </form>
    // </FilterModal>
  );
};

export default Filter;
