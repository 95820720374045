import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useFieldArray } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Alert,
  AlertIcon,
  Box,
  Input,
  CheckboxGroup,
  SimpleGrid,
  Checkbox,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";

import Search from "../../../shared/search/Search";

import { updateAccessProduct } from "../../../../store/products/productsSlice";
import { getUsers } from "../../../../store/users/usersSlice";

import theme from "../../../global/theme";
import Pagination from "../../../shared/pagination/Pagination";

const UpdateAccessModal = ({ data, onClose }) => {
  const { t } = useTranslation();
  const products = useSelector((state) => state.products);
  const users = useSelector((state) => state.users);

  const dispatch = useDispatch();
  const { register, handleSubmit, watch, control, getValues } = useForm({
    defaultValues: data,
  });

  watch(["access_to", "access_type"]);

  const {
    fields: accessTo,
    append: appendAccess,
    remove: removeAccess,
  } = useFieldArray({
    name: "access_to",
    control,
  });

  const [salessPage, setSalessPage] = useState(1);
  const [salessQuery, setSalessQuery] = useState("");

  useEffect(() => {
    dispatch(
      getUsers({
        page: salessPage,
        query: salessQuery,
        filter: JSON.stringify({ role: "marketer" }),
      })
    );
  }, [dispatch, salessPage, salessQuery]);

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent borderRadius={20} paddingBlock={4} bg={theme.dark}>
        <form
          onSubmit={handleSubmit((values) => {
            if (
              values.access_type === "private" &&
              values.access_to.length === 0
            ) {
              return alert("اختر على الاقل مسوق");
            }

            if (values.access_type === "public") {
              values.access_to = [];
            }

            for (let i = 0; i < values.access_to.length; i++) {
              values.access_to[i] = values.access_to[i]._id;
            }

            values.access_to = [...new Set(values.access_to)];

            console.log(values);

            dispatch(updateAccessProduct(values))
              .unwrap()
              .then((res) => {
                onClose();
              });
          })}
        >
          <ModalHeader
            textAlign="center"
            color="orange"
            textTransform="uppercase"
            fontSize={22}
          >
            تحديث حالة المنتج
          </ModalHeader>
          <ModalBody>
            {products.errors.length > 0 && (
              <Alert status="error" variant="left-accent" marginBottom={8}>
                <AlertIcon />
                {products.errors?.map((error, index) => (
                  <Text key={index}>{error?.msg}</Text>
                ))}
              </Alert>
            )}

            <FormControl mb={8}>
              <FormLabel
                fontWeight="bold"
                textTransform="capitalize"
                color="white"
              >
                حالة المنتج
              </FormLabel>
              <Flex>
                <Flex me={6}>
                  <input
                    type="radio"
                    value="public"
                    color={theme.light}
                    {...register("access_type")}
                  />
                  <Box ms={2} color={theme.light}>
                    عام
                  </Box>
                </Flex>

                <Flex>
                  <input
                    type="radio"
                    value="private"
                    color={theme.light}
                    {...register("access_type")}
                  />
                  <Box ms={2} color={theme.light}>
                    برايفت
                  </Box>
                </Flex>
              </Flex>
            </FormControl>

            {getValues("access_type") === "private" && (
              <Box>
                <FormControl>
                  <FormLabel color="white">البحث عن مستخدم</FormLabel>
                  <Input
                    type="text"
                    bg={theme.bg}
                    color={theme.dark}
                    border="none"
                    borderRadius={4}
                    placeholder="اكتب للبحث..."
                    value={salessQuery}
                    onChange={(e) => setSalessQuery(e.target.value)}
                  />
                </FormControl>

                <FormControl mt={6}>
                  <Box marginInline={5}>
                    <FormLabel fontWeight="bold" color="white" mb={4}>
                      اختر المسوقين
                    </FormLabel>

                    {/* <Checkbox
                      isChecked={selectAll}
                      onChange={handleSelectAll}
                      colorScheme="orange"
                      color="white"
                    >
                      اختر الجميع
                    </Checkbox> */}

                    <CheckboxGroup
                      value={getValues("access_to").map((user) => user._id)}
                    >
                      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={2}>
                        {users?.data?.data?.map((user) => (
                          <Checkbox
                            key={user._id}
                            value={user._id}
                            colorScheme="orange"
                            color="white"
                            onChange={() => appendAccess(user)}
                          >
                            {user.name || user.email}
                          </Checkbox>
                        ))}
                      </SimpleGrid>
                    </CheckboxGroup>
                  </Box>
                </FormControl>

                <Flex justifyContent="flex-end" mt={4}>
                  <Pagination
                    page={salessPage}
                    itemsCount={users.data?.itemsCount ?? 0}
                    onChange={(page) => setSalessPage(page)}
                  />
                </Flex>

                <Text color="white" mt={4}>
                  {`تم تحديد ${getValues("access_to").length} مسوقين`}
                </Text>
                {accessTo.length === 0 ? (
                  <Box mt={4} textAlign="center" color={theme.light}>
                    لا يوجد مسوقين
                  </Box>
                ) : (
                  <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={2} mt={4}>
                    {accessTo.map((el, index) => (
                      <Flex marginBlock={4} alignItems="center" key={el.id}>
                        <Box w="calc(80% - 50px)" color={theme.light}>
                          {el.name}
                        </Box>
                        <Button
                          type="button"
                          bg={theme.error}
                          color={theme.light}
                          onClick={() => removeAccess(index)}
                          ms={3}
                        >
                          <FiIcons.FiDelete />
                        </Button>
                      </Flex>
                    ))}
                  </SimpleGrid>
                )}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="flex-end">
              <Button
                type="submit"
                rightIcon={<FiIcons.FiSave />}
                color="white"
                bg="green"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                isLoading={products.isLoading}
                _hover={{ background: "green" }}
              >
                {t("general.save")}
              </Button>
              <Button
                type="button"
                onClick={onClose}
                rightIcon={<FiIcons.FiMinimize2 />}
                color="white"
                bg="red.600"
                paddingInline={4}
                paddingBlock={2}
                height="auto"
                textTransform="capitalize"
                marginInlineStart={4}
                isLoading={products.isLoading}
                _hover={{ background: "red.600" }}
              >
                {t("general.close")}
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default UpdateAccessModal;
