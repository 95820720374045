import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  Flex,
  Button,
  Text,
  SimpleGrid,
  Alert,
  AlertIcon,
  Spinner,
  FormControl,
  FormLabel,
  Switch,
} from "@chakra-ui/react";

import Pagination from "../../shared/pagination/Pagination";
import Breadcrumbs from "../../shared/breadcrumbs/Breadcrumbs";
import ProductsTable from "./ProductsTable";
import CreateModal from "./actions/CreateModal";
import UpdateModal from "./actions/UpdateModal";
import UpdateAccessModal from "./actions/UpdateAccessModal";
import Filter from "./actions/Filter";

import { getProducts } from "../../../store/products/productsSlice";

import { ProductsWrapper } from "./ProductsStyle";

import theme from "../../global/theme";
import InputSearch from "../../shared/inputSearch/InputSearch";
import UpdateStockModal from "./actions/UpdateStockModal";
import { useSearchParams } from "react-router-dom";
import { getStockRules } from "../../../store/stock/stockSlice";

const Products = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products);
  const auth = useSelector((state) => state.auth);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = +searchParams.get("page") || 1;
  const query = searchParams.get("query") || "";

  // State for filter and switches
  const [filter, setFilter] = useState({
    is_active: true,
    is_archived: false,
  });

  const [showCreateProduct, setShowCreateProduct] = useState(false);
  const [showUpdateStock, setShowUpdateStock] = useState(false);
  const [showUpdateProduct, setShowUpdateProduct] = useState(false);
  const [showUpdateAccess, setShowUpdateAccess] = useState(false);

  useEffect(() => {
    dispatch(getProducts({ page, query, filter: JSON.stringify(filter) }));
  }, [dispatch, page, query, filter, showCreateProduct]);

  useEffect(() => {
    dispatch(getStockRules());
  }, [dispatch]);

  // Handle switch toggle
  const handleSwitchToggle = (field) => {
    if (field == "is_archived" && !filter?.is_archived) {
      setFilter((prev) => ({
        ...prev,
        is_active: false,
      }));
    }
    setFilter((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <ProductsWrapper>
      <Breadcrumbs
        currentPage={t("pages.products.products")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {products.errors.length > 0 && (
        <Alert status="error" variant="left-accent" marginBottom={8}>
          <AlertIcon />
          {products.errors?.map((error, index) => (
            <Text key={index}>{error?.msg}</Text>
          ))}
        </Alert>
      )}

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
        <Flex
          marginBottom={8}
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            bg="red.600"
            textTransform="uppercase"
            fontWeight="bold"
            fontSize={18}
            marginInlineEnd={4}
            color="white"
            _hover={{ bg: "red.600" }}
            onClick={() => setShowCreateProduct(true)}
          >
            {t("general.create")}
          </Button>
          {auth.user?.rule?.name === "superadmin" && (
            <Button
              bg="red.600"
              textTransform="uppercase"
              fontWeight="bold"
              fontSize={18}
              marginInlineEnd={4}
              color="white"
              _hover={{ bg: "red.600" }}
              onClick={() => setShowUpdateStock(true)}
            >
              تعديل الاستوك
            </Button>
          )}
        </Flex>
        <Flex
          marginBottom={8}
          justifyContent="space-between"
          alignItems="center"
        >
          <FormLabel fontWeight="bold" textTransform="capitalize">
            {t("pages.products.is_active")}
          </FormLabel>
          <Flex alignItems="center">
            <Switch
              colorScheme="teal"
              size="lg"
              isChecked={filter.is_active}
              onChange={() => handleSwitchToggle("is_active")}
            />
          </Flex>

          <FormLabel fontWeight="bold" textTransform="capitalize">
            مؤرشف
          </FormLabel>
          <Flex alignItems="center">
            <Switch
              colorScheme="teal"
              size="lg"
              isChecked={filter.is_archived}
              onChange={() => handleSwitchToggle("is_archived")}
            />
          </Flex>
        </Flex>
      </SimpleGrid>
      <Flex
        marginBottom={8}
        justifyContent="flex-end"
        alignItems="center"
        gap={4}
        flexDirection={"row"}
      >
        <Filter
          handleFilter={(values) => setFilter({ ...filter, ...values })}
          handleClearFilter={() =>
            setFilter({ is_active: null, is_archived: null })
          }
          disablesBtns={products.isLoading}
        />
        <InputSearch
          isLoading={products.isLoading}
          handleSearch={(query) => {
            searchParams.set("query", query);
            searchParams.set("page", 1);
            setSearchParams(searchParams);
          }}
        />
      </Flex>

      {products.isLoading ? (
        <Flex padding={6} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : products.data?.itemsCount > 0 ? (
        <ProductsTable
          data={products.data.data}
          page={page}
          handleUpdate={(el) => setShowUpdateProduct(el)}
          handleUpdateAccess={(el) => setShowUpdateAccess(el)}
        />
      ) : (
        <Flex
          textAlign="center"
          bg={theme.light}
          boxShadow={theme.shadow}
          height={200}
          alignItems="center"
          justifyContent="center"
          borderRadius={16}
        >
          <Text
            fontSize={18}
            textTransform="capitalize"
            color="gray.300"
            fontWeight="bold"
          >
            {t("pages.products.no_products")}
          </Text>
        </Flex>
      )}

      {products.data?.itemsCount > 0 && !products.isLoading && (
        <Flex justifyContent="flex-end">
          <Pagination
            page={page}
            itemsCount={products.data?.itemsCount ?? 0}
            onChange={(page) => {
              searchParams.set("page", page);
              setSearchParams(searchParams);
            }}
          />
        </Flex>
      )}

      {showCreateProduct && (
        <CreateModal onClose={() => setShowCreateProduct(false)} />
      )}
      {showUpdateProduct && (
        <UpdateModal
          data={showUpdateProduct}
          onClose={() => setShowUpdateProduct(false)}
        />
      )}
      {showUpdateAccess && (
        <UpdateAccessModal
          data={showUpdateAccess}
          onClose={() => setShowUpdateAccess(false)}
        />
      )}
      {showUpdateStock && (
        <UpdateStockModal
          onClose={() => {
            setShowUpdateStock(false);
            // dispatch(getProducts({ page, query, filter }));
          }}
        />
      )}
    </ProductsWrapper>
  );
};

export default Products;
