import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { keyframes } from "@chakra-ui/react";

import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
  Avatar,
  Box,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";

import * as FiIcons from "react-icons/fi";
import { RiBatteryLowLine } from "react-icons/ri";
import { toggleSidebar } from "../../../store/sidebar/sidebarSlice";
import { getNotifications } from "../../../store/notifications/notificationsSlice";
import { logout } from "../../../store/auth/authSlice";
import { AiTwotoneAlert } from "react-icons/ai";
import { NavbarWrapper } from "./NavbarStyle";
import { FcLowPriority } from "react-icons/fc";
import theme from "../../global/theme";
import AlertDrawer from "../../modules/announcements/AlertDrawer";
import {
  getMinProducts,
  getMostInactiveProducts,
} from "../../../store/products/productsSlice";

const Navbar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const cart = useSelector((state) => state.cart);
  const sidebar = useSelector((state) => state.sidebar);
  const notifications = useSelector((state) => state.notifications);
  const alerts = useSelector((state) => state.alerts);
  const products = useSelector((state) => state.products);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getNotifications({ page: 1, query: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (
      auth.user.role === "admin" &&
      (auth.user?.rule?.permissions?.includes("manage_products") ||
        auth.user?.email == "shimaabayoumi182@gmail.com")
    ) {
      dispatch(getMinProducts({ page: 1 }));
      dispatch(getMostInactiveProducts({ page: 1 }));
    }
  }, [dispatch, auth]);
  return (
    <NavbarWrapper className={`${sidebar.isNotOpened ? "active" : ""}`}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        me={{ base: 2, md: 0 }}
      >
        {/* start side */}
        <Button
          p={0}
          bg="none"
          width="auto"
          color={theme.dark}
          _hover={{ bg: "none" }}
          _focus={{ outline: "none" }}
          marginInlineEnd={2}
          onClick={() => dispatch(toggleSidebar())}
        >
          <FiIcons.FiAlignRight size={21} />
        </Button>

        {/* end side */}
        <Flex alignItems="center">
          {(auth.user?.role == "marketer" ||
            auth.user?.role == "moderator") && (
            <Menu>
              <MenuButton
                p={{ base: 1, md: 3 }}
                as={Button}
                bg={theme.bg}
                color={theme.dark}
                onClick={() => navigate("/cart")}
                _hover={{ bg: "none" }}
                _focus={{ outline: "none" }}
                me={{ base: 1, md: 3 }}
              >
                <Flex>
                  <FiIcons.FiShoppingCart size={20} />
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="50%"
                    bg={theme.error}
                    color={theme.light}
                    fontSize={{ base: "10px", md: "14px" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    {cart.items.length}
                  </Box>
                </Flex>
              </MenuButton>
            </Menu>
          )}

          {(auth.user?.role == "marketer" ||
            (auth.user.role === "admin" &&
              auth.user?.rule?.permissions?.includes("manage_products"))) && (
            <Menu>
              <MenuButton
                p={{ base: 1, md: 3 }}
                as={Button}
                bg={theme.bg}
                me={{ base: 1, md: 3 }}
                color={theme.dark}
                onClick={() => onOpen()}
                _hover={{ bg: "none" }}
                _focus={{ outline: "none" }}
              >
                <Flex>
                  <AiTwotoneAlert
                    style={{
                      animation: "pulse 2s infinite",
                      transformOrigin: "center",
                    }}
                    size={20}
                  />
                  <Box
                    w="20px"
                    h="20px"
                    borderRadius="50%"
                    bg={theme.error}
                    color={theme.light}
                    fontSize={{ base: "10px", md: "14px" }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    {alerts.data?.isNotRead}
                  </Box>
                </Flex>
              </MenuButton>
            </Menu>
          )}
          {auth.user.role === "admin" &&
            auth.user?.rule?.permissions?.includes("manage_products") && (
              <Menu>
                <MenuButton
                  p={{ base: 1, md: 3 }}
                  as={Button}
                  bg={theme.bg}
                  me={{ base: 1, md: 3 }}
                  color={theme.dark}
                  onClick={() => navigate("low_stock")}
                  _hover={{ bg: "none" }}
                  _focus={{ outline: "none" }}
                >
                  <Flex>
                    <RiBatteryLowLine
                      style={{
                        animation: "pulse 2s infinite",
                        transformOrigin: "center",
                      }}
                      size={20}
                    />
                    <Box
                      w="20px"
                      h="20px"
                      borderRadius="50%"
                      bg={theme.error}
                      color={theme.light}
                      fontSize={{ base: "10px", md: "14px" }}
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                    >
                      {products.min?.itemsCount}
                    </Box>
                  </Flex>
                </MenuButton>
              </Menu>
            )}
          {auth.user.role === "admin" &&
            (auth.user?.rule?.permissions?.includes("manage_products") ||
              auth.user?.email == "shimaabayoumi182@gmail.com") && (
              <Menu>
                <MenuButton
                  p={{ base: 1, md: 3 }}
                  as={Button}
                  bg={theme.bg}
                  me={{ base: 1, md: 3 }}
                  color={theme.dark}
                  onClick={() => navigate("low_orders")}
                  _hover={{ bg: "none" }}
                  _focus={{ outline: "none" }}
                >
                  <Flex>
                    <FcLowPriority
                      style={{
                        animation: "pulse 2s infinite",
                        transformOrigin: "center",
                      }}
                      size={20}
                    />
                    <Box
                      w="20px"
                      h="20px"
                      borderRadius="50%"
                      bg={theme.error}
                      color={theme.light}
                      fontSize={{ base: "10px", md: "14px" }}
                      justifyContent={"center"}
                      alignItems={"center"}
                      display={"flex"}
                    >
                      {products.low?.itemsCount}
                    </Box>
                  </Flex>
                </MenuButton>
              </Menu>
            )}
          <Menu>
            <MenuButton
              p={{ base: 1, md: 3 }}
              as={Button}
              bg={theme.bg}
              color={theme.dark}
              onClick={() => navigate("/notifications")}
              _hover={{ bg: "none" }}
              _focus={{ outline: "none" }}
            >
              <Flex>
                <FiIcons.FiBell size={20} />
                <Box
                  w="20px"
                  h="20px"
                  borderRadius="50%"
                  bg={theme.error}
                  color={theme.light}
                  fontSize={{ base: "10px", md: "14px" }}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  {notifications.data?.isNotRead > 99
                    ? "99+"
                    : notifications.data?.isNotRead}
                </Box>
              </Flex>
            </MenuButton>
          </Menu>

          <Menu>
            <MenuButton
              p={{ base: 1, md: 3 }}
              as={Button}
              bg="none"
              color={theme.dark}
              _hover={{ bg: "none" }}
              _focus={{ outline: "none" }}
            >
              <Flex alignItems="center">
                <Avatar name={auth.user?.name} size="sm" />
                <Text
                  className="profile-text"
                  marginInline={3}
                  textTransform="capitalize"
                  fontSize={14}
                >
                  {auth.user?.name && auth.user.name.slice(0, 10)}
                </Text>
                <FiIcons.FiChevronDown size={14} />
              </Flex>
            </MenuButton>
            <MenuList minW="170px">
              <Box p={{ base: 1, lg: 3 }}>
                <Box>
                  <Text color={theme.dark} textTransform="capitalize">
                    {auth.user?.name && auth.user.name.slice(0, 10)}
                  </Text>
                  <Text color={theme.secColor}>
                    {auth.user?.role}: <b>{auth.user?.code}</b>
                  </Text>
                </Box>
                <Divider marginBlock={2} />
                <Flex
                  as={Link}
                  to="/profile"
                  alignItems="center"
                  color={theme.dark}
                  textTransform="capitalize"
                >
                  <FiIcons.FiUser />
                  <Text ms={2}>{t("layout.navbar.profile")}</Text>
                </Flex>
                <Divider marginBlock={2} />
                <Box>
                  <Text
                    as={Button}
                    bg="none"
                    p={0}
                    _hover={{ bg: "none" }}
                    color={theme.dark}
                    textTransform="capitalize"
                    onClick={() => dispatch(logout())}
                  >
                    {t("layout.navbar.logout")}
                  </Text>
                </Box>
              </Box>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      <AlertDrawer isOpen={isOpen} onClose={onClose} />
    </NavbarWrapper>
  );
};

export default Navbar;
