import React, { useEffect } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Badge,
  Divider,
  Stack,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import { FiPlusCircle, FiMinusCircle, FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { getOrderLogs } from "../../../../store/orders/ordersSlice";
import { useParams } from "react-router-dom";

const OrderLogsModal = ({ isOpen, onClose }) => {
  const { orderId } = useParams();

  const dispatch = useDispatch();
  const { orderLogs } = useSelector((state) => state.orders);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (isOpen) {
      dispatch(getOrderLogs({ id: orderId }));
    }
  }, [isOpen, orderId, dispatch]);

  const getActionDetails = (change) => {
    if (change.oldQty === 0) {
      return {
        label: "إضافة",
        icon: FiPlusCircle,
        color: "green",
        description: `تم إضافة ${change.newQty} قطع.`,
      };
    }
    if (change.newQty === 0) {
      return {
        label: "حذف",
        icon: FiMinusCircle,
        color: "red",
        description: `تم حذف ${change.oldQty} قطع.`,
      };
    }
    return {
      label: "تغيير",
      icon: FiEdit,
      color: "blue",
      description: `الكمية  اتغييرت من  ${change.oldQty} إلي ${change.newQty}.`,
    };
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>سجل التغييرات </ModalHeader>
        <ModalBody>
          <VStack align="stretch" spacing={4}>
            {orderLogs?.length ? (
              orderLogs.map((log) => (
                <Box
                  key={log._id}
                  borderWidth="1px"
                  borderRadius="lg"
                  p={4}
                  boxShadow="md"
                >
                  <VStack justify="space-between" mb={2}>
                    <Text fontWeight="bold" fontSize="md">
                      ID: {log.order}
                    </Text>
                    <Badge colorScheme="blue">
                      أخر تحديث: {new Date(log.updatedAt).toLocaleString()}
                    </Badge>
                  </VStack>
                  <Divider my={2} />
                  <Stack spacing={3}>
                    <SimpleGrid columns={2} spacing={4}>
                      {/* Render New Fields */}
                      {log?.client_name && (
                        <Text>
                          <strong>العميل:</strong> {log?.client_name}
                        </Text>
                      )}
                      {log?.client_phone1 && (
                        <Text>
                          <strong>الهاتف 1:</strong> {log?.client_phone1}
                        </Text>
                      )}
                      {log?.client_phone1 && (
                        <Text>
                          <strong>الهاتف 2:</strong> {log?.client_phone2}
                        </Text>
                      )}
                      {log?.client_address && (
                        <Text>
                          <strong>العنوان:</strong> {log?.client_address}
                        </Text>
                      )}
                      {log?.shipping && (
                        <Text>
                          <strong>الشحن:</strong> {log?.shipping} جنيه
                        </Text>
                      )}
                      {log?.total && (
                        <Text>
                          <strong>الإجمالي:</strong> {log?.total} جنيه
                        </Text>
                      )}
                      {log?.commission && (
                        <Text>
                          <strong>العمولة:</strong> {log?.commission} جنيه
                        </Text>
                      )}
                    </SimpleGrid>

                    {log.itemChanges.map((change) => {
                      const { label, icon, color, description } =
                        getActionDetails(change);

                      return (
                        <Box
                          key={change._id}
                          p={3}
                          bg="gray.50"
                          borderRadius="md"
                        >
                          <HStack justify="space-between">
                            <Text fontWeight="bold">
                              المنتج: {change.product.name} (
                              {change.product.barcode})
                            </Text>
                            <Text fontWeight="bold">
                              الخاصية:
                              {
                                change?.product?.properties.find(
                                  (prop) => prop._id === change.property
                                )?.key
                              }
                            </Text>
                            <Badge colorScheme={color}>
                              <HStack>
                                <Icon as={icon} />
                                <Text>{label}</Text>
                              </HStack>
                            </Badge>
                          </HStack>
                          <Text>الوصف: {description}</Text>
                          <Text>توضيح: {change.reason}</Text>
                        </Box>
                      );
                    })}
                  </Stack>
                  <Divider my={2} />
                  <Text fontSize="sm" color="gray.500">
                    تم التعديل بواسطة: {log.changedBy.name}
                    {/* {auth?.user?.role === "admin" && log.changedBy.email} */}
                  </Text>
                </Box>
              ))
            ) : (
              <Text>لا يوجد سجلات</Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} colorScheme="blue">
            إغلاق
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default OrderLogsModal;
