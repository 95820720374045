import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  FormControl,
  FormLabel,
  Input,
  VStack,
  Button,
  Select,
} from "@chakra-ui/react";

import Search from "../../../shared/search/Search";
import FilterModal from "../../../shared/modal/FilterModal";

import { getUsers } from "../../../../store/users/usersSlice";

import theme from "../../../global/theme";
import { useDispatch, useSelector } from "react-redux";

const Filter = ({ handleFilter, handleClearFilter, disablesBtns }) => {
  const { t } = useTranslation();
  const { register, getValues, setValue, watch, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [merchantsPage, setMerchantsPage] = useState(1);
  const [merchantsSize] = useState(10);
  const [merchantsQuery, setMerchantsQuery] = useState("");

  useEffect(() => {
    dispatch(
      getUsers({
        page: merchantsPage,
        size: merchantsSize,
        query: merchantsQuery,
        filter: JSON.stringify({ role: "merchant" }),
      })
    );
  }, [dispatch, merchantsPage, merchantsSize, merchantsQuery]);

  watch(["merchant"]);

  return (
    // <FilterModal>
    <form
      style={{
        width: "25%",
        marginBottom: "20px",
      }}
      onSubmit={handleSubmit((values) => {
        for (let key in values) {
          if (values[key]) {
            if (key === "is_active") {
              if (values[key] === "active") {
                values.is_active = true;
              } else if (values[key] === "not_active") {
                values.is_active = false;
              }
            } else if (key === "merchant") {
              values.merchant = values.merchant?._id;
            }
          } else {
            delete values[key];
          }
        }
        console.log(values);
        handleFilter(JSON.stringify(values));
      })}
    >
      {/* <FormControl>
          <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
            {t("general.from")}
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.from")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("from")}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
            {t("general.to")}
          </FormLabel>
          <Input
            type="date"
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("general.to")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("to")}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontWeight="bold" textTransform="capitalize" color="white">
            {t("pages.products.is_active")}
          </FormLabel>
          <Select
            bg={theme.bg}
            color={theme.dark}
            border="none"
            borderRadius={4}
            placeholder={t("pages.products.is_active")}
            _placeholder={{ textTransform: "capitalize" }}
            {...register("is_active")}
          >
            <option value="">الكل</option>
            <option value="active">نشط</option>
            <option value="not_active">غير نشط</option>
          </Select>
        </FormControl> */}

      <FormControl>
        <FormLabel fontWeight="bold" textTransform="capitalize">
          التاجر
        </FormLabel>
        <Search
          color={theme.dark}
          bg={theme.border}
          page={merchantsPage}
          itemsCount={users.data?.itemsCount}
          data={users.data?.data}
          prop="name"
          currentElement={getValues("merchant")}
          handleChangePage={(page) => {
            setMerchantsPage(page);
          }}
          handleChangeQuery={(query) => {
            setMerchantsQuery(query);
          }}
          handleSelected={(item) => {
            setValue("merchant", item);
            let values = {};
            values.merchant = item?._id;
            console.log(values);
            handleFilter(values);
          }}
        />
      </FormControl>

      {/* <Button
        type="submit"
        isLoading={disablesBtns}
        mt={8}
        width="100%"
        colorScheme="blue"
      >
        {t("general.save")}
      </Button>
      <Button
        type="button"
        isLoading={disablesBtns}
        mt={2}
        width="100%"
        colorScheme="red"
        onClick={handleClearFilter}
      >
        {t("general.reset")}
      </Button> */}
    </form>
    // </FilterModal>
  );
};

export default Filter;
