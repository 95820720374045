import React from "react";
import { useTranslation } from "react-i18next";

import Table from "../../shared/table/Table";
import { Tr, Th, Button } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { FiEdit, FiChevronDown } from "react-icons/fi";

const MarktersAccountsTable = ({
  data,
  page,
  setShowUpdateAccount,
  setAccount,
  handleOpen,
  setSort,
  sort,
}) => {
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);

  return (
    <Table variant="simple">
      <thead>
        <Tr>
          <Th>#</Th>
          <Th cursor="pointer" onClick={() => setSort("")}>
            {t("pages.accounts.marketer")}
            {sort === "" && <FiChevronDown />}
          </Th>
          <Th>الكود</Th>
          <Th>رقم الهاتف</Th>
          <Th cursor="pointer" onClick={() => setSort("pending")}>
            {t("pages.accounts.pending")}
            {sort === "pending" && <FiChevronDown />}
          </Th>
          <Th cursor="pointer" onClick={() => setSort("preparing")}>
            {t("pages.accounts.preparing")}
            {sort === "preparing" && <FiChevronDown />}
          </Th>
          <Th cursor="pointer" onClick={() => setSort("shipped")}>
            {t("pages.accounts.shipped")}
            {sort === "shipped" && <FiChevronDown />}
          </Th>
          <Th cursor="pointer" onClick={() => setSort("available")}>
            {t("pages.accounts.available")}
            {sort === "available" && <FiChevronDown />}
          </Th>
          <Th cursor="pointer" onClick={() => setSort("availableToWithdraw")}>
            رصيد قابل للسحب
            {sort === "availableToWithdraw" && <FiChevronDown />}
          </Th>

          {/* {auth.user?.rule?.name === "superadmin" && <Th>تعديل الأرصدة</Th>} */}
          {(auth.user?.rule?.name === "admin" ||
            auth.user?.rule?.name === "superadmin") && <Th>فتح الحساب</Th>}
        </Tr>
      </thead>
      <tbody>
        {data.map((el, index) => (
          <tr key={el._id}>
            <td>{(page - 1) * 10 + (index + 1)}</td>
            <td>{el.marketer?.name}</td>
            <td>{el.marketer?.code}</td>
            <td>{el.marketer?.phone}</td>
            <td>{el.pending}</td>
            <td>{el.preparing}</td>
            <td>{el.shipped}</td>
            <td>{el.available}</td>
            <td>{el.availableToWithdraw}</td>
            {/* {auth.user?.rule?.name === "superadmin" && (
              <td>
                <Button
                  bg="transparent"
                  color="red"
                  size="xs"
                  onClick={() => {
                    setShowUpdateAccount(true);
                    setAccount(el);
                  }}
                >
                  <FiEdit size={20} />
                </Button>
              </td>
            )} */}
            {(auth.user?.rule?.name === "superadmin" ||
              auth.user?.rule?.name === "admin") && (
              <td>
                <Button
                  bg="green.600"
                  color="white"
                  size="xs"
                  onClick={() => handleOpen(el?.marketer)}
                >
                  فتح الحساب
                </Button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MarktersAccountsTable;
