import React from "react";
import { useTranslation } from "react-i18next";
import {
  Tag,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  Text,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

const MinProductsTable = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Box
      bg={tableBg}
      shadow="md"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      mt={5}
    >
      <Table variant="simple" size="md">
        <Thead>
          <Tr>
            <Th>{t("pages.products.name")}</Th>
            <Th>خصائص المنتج</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((product) => (
            <Tr
              key={product.name}
              cursor="pointer"
              onClick={() => navigate(`/available_products/${product._id}`)}
            >
              {/* Product Name */}
              <Td>
                <VStack align="start">
                  <Text fontWeight="semibold" color={textColor}>
                    {product.name}
                  </Text>
                </VStack>
              </Td>

              {/* Properties */}
              <Td>
                {product.properties.length > 0 ? (
                  <Flex gap={2} align="start" flexWrap="wrap">
                    {product.properties.map((prop) => (
                      <Tag
                        key={prop._id}
                        size="md"
                        variant="solid"
                        colorScheme={
                          prop?.value < (prop?.min || 50) ? "red" : "green"
                        } // Using 50 as the default min value
                        mb={1}
                      >
                        {prop.key}: {prop?.value} > {prop?.min || 50}
                      </Tag>
                    ))}
                  </Flex>
                ) : (
                  <Text fontSize="sm" color="gray.500">
                    {t("pages.products.noPropertiesBelowMin")}
                  </Text>
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default MinProductsTable;
