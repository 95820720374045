import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Flex,
  Box,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/react";
import { FiCheckCircle } from "react-icons/fi";
import theme from "../../global/theme";
import { updateNotifications } from "../../../store/notifications/notificationsSlice";

import Pagination from "../../shared/pagination/Pagination";
import { getAlerts, readMyAlerts } from "../../../store/alerts/alertSlice";
import AlertsList from "./AlertsList";

const AlertDrawer = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts);
  const auth = useSelector((state) => state.auth);

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (auth?.user?.role == "admin") {
      dispatch(getAlerts({ page, filter: { category: "suggestion" } }));
    } else {
      dispatch(getAlerts({ page }));
    }
  }, [dispatch, page, auth]);

  return (
    <Drawer
      isOpen={isOpen}
      placement="left"
      // size={{ base: "xs", md: "sm" }}
      onClose={onClose}
    >
      <DrawerOverlay>
        <DrawerContent px={2}>
          <DrawerHeader borderBottomWidth="1px">
            <Flex justifyContent="space-between" alignItems="center">
              <Text>التنبيهات</Text>
              <Box>
                <Button
                  type="button"
                  bg="red.700"
                  color="white"
                  _hover={{ bg: "red.500" }}
                  leftIcon={<FiCheckCircle />} // Add the icon here
                  onClick={() =>
                    dispatch(readMyAlerts({}))
                      .unwrap()
                      .then(() => {
                        dispatch(getAlerts({ page }));
                      })
                  }
                >
                  الكل كمقروء
                </Button>
              </Box>
            </Flex>
          </DrawerHeader>

          <DrawerBody>
            {alerts.data?.itemsCount > 0 ? (
              <AlertsList data={alerts.data?.data} />
            ) : (
              <Flex
                textAlign="center"
                bg={theme.light}
                height={200}
                alignItems="center"
                boxShadow={theme.shadow}
                justifyContent="center"
                borderRadius={16}
              >
                <Text
                  fontSize={18}
                  textTransform="capitalize"
                  color="gray.300"
                  fontWeight="bold"
                >
                  لا توجد تنبيهات
                </Text>
              </Flex>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Pagination
              page={page}
              itemsCount={alerts.data?.itemsCount ?? 0}
              onChange={(page) => setPage(page)}
            />
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default AlertDrawer;
