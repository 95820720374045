import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Initial state
const initialState = {
  isLoading: false,
  data: {},
  possible: {},

  errors: [],
};

// Thunks
export const getBlacklist = createAsyncThunk(
  "products/getBlacklist",
  async (args, thunkApi) => {
    try {
      const {
        page = 1,
        size = 10,
        query = "",
        filter = JSON.stringify({}),
      } = args;
      const { data } = await axios.get(
        `/api/blacklist?page=${page}&size=${size}&filter=${filter}&query=${query}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const getPossibleBlacklist = createAsyncThunk(
  "products/getPossibleBlacklist",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.get(`/api/getPossibleBlacklist`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const createBlacklist = createAsyncThunk(
  "products/createBlacklist",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.post("/api/blacklist", args, {
        headers: {
          Authorization: thunkApi.getState().auth.token,
        },
      });
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

export const updateBlacklist = createAsyncThunk(
  "products/updateBlacklist",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.patch(
        `/api/blacklist/${args._id}`,
        args.values,
        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);
export const deleteBlacklist = createAsyncThunk(
  "products/deleteBlacklist",
  async (args, thunkApi) => {
    try {
      const { data } = await axios.delete(
        `/api/blacklist/${args._id}`,

        {
          headers: {
            Authorization: thunkApi.getState().auth.token,
          },
        }
      );
      return thunkApi.fulfillWithValue(data);
    } catch (error) {
      return thunkApi.rejectWithValue(
        error.response?.status !== 400
          ? { errors: [{ msg: "something went wrong" }] }
          : error.response.data
      );
    }
  }
);

const blacklistSlice = createSlice({
  name: "blacklist",
  initialState,
  extraReducers: (builder) => {
    builder
      // Handle getProducts
      .addCase(getBlacklist.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getBlacklist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errors = [];
      })
      .addCase(getBlacklist.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      })
      // Handle getPossibleBlacklist
      .addCase(getPossibleBlacklist.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(getPossibleBlacklist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.possible = action.payload;
        state.errors = [];
      })
      .addCase(getPossibleBlacklist.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errors = action.payload.errors;
      })
      // Handle read_all_alert
      .addCase(deleteBlacklist.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(deleteBlacklist.fulfilled, (state, action) => {
        state.isLoading = false;

        state.errors = [];
      })
      .addCase(deleteBlacklist.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })

      // Handle updateAccessProduct
      .addCase(updateBlacklist.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(updateBlacklist.fulfilled, (state, action) => {
        state.isLoading = false;
        if (state.data.data) {
          state.data.data = state.data.data.map((el) =>
            el._id === action.payload.data._id ? action.payload.data : el
          );
        }
        state.errors = [];
      })
      .addCase(updateBlacklist.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      })
      // Handle updateAccessProduct
      .addCase(createBlacklist.pending, (state) => {
        state.isLoading = true;
        state.errors = [];
      })
      .addCase(createBlacklist.fulfilled, (state, action) => {
        state.isLoading = false;

        state.errors = [];
      })
      .addCase(createBlacklist.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload.errors;
      });
  },
});

export default blacklistSlice.reducer;
