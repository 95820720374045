import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import * as FiIcons from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Box,
  Divider,
  Image,
  Link,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useToast,
  Flex,
  Icon,
} from "@chakra-ui/react";

import Breadcrumbs from "../../../shared/breadcrumbs/Breadcrumbs";
import { MdHistory } from "react-icons/md";
import { addToCart } from "../../../../store/cart/cartSlice";
import { getProductById } from "../../../../store/products/productsSlice";

import { ProductsWrapper } from "../ProductsStyle";

import theme from "../../../global/theme";
import systemBg from "../../../../assets/images/logo.png";

import { FaTag } from "react-icons/fa";
import ProductLogs from "./ProductLogs";
const ProductDetails = () => {
  const { t } = useTranslation();
  const toast = useToast({ status: "success", position: "top" });
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const products = useSelector((state) => state.products);
  const cart = useSelector((state) => state.cart);

  const { productId } = useParams();

  useEffect(() => {
    dispatch(getProductById({ productId }));
  }, [dispatch, productId]);

  const product = products.product;

  const [showLogsModal, setShowLogsModal] = useState(false);

  const showLogs = (propertyId) => {
    // Fetch logs for the selected property, you can use your existing logs data for this
    setShowLogsModal(propertyId);
  };

  return (
    <ProductsWrapper>
      <Breadcrumbs
        currentPage={t("pages.products.products")}
        pages={[{ name: `${t("pages.dashboard.dashboard")}`, path: "/" }]}
      />

      {!products.product ? (
        <h1>please wait ....</h1>
      ) : (
        <>
          <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={8}>
            <Box>
              <Text fontSize="23px" mb={6}>
                {t("pages.products.image")}
              </Text>
              <Box bg={theme.light} boxShadow={theme.shadow} h="300px" p={3}>
                <Image
                  h="100%"
                  w="100%"
                  borderRadius="5px"
                  objectFit="contain"
                  src={
                    product?.image ? `/products/${product?.image}` : systemBg
                  }
                />
              </Box>
            </Box>
            <Box>
              <Text fontSize="23px" mb={6}>
                {t("pages.products.note")}
              </Text>
              <Box
                bg={theme.light}
                shadow={theme.shadow}
                p={3}
                borderRadius="5px"
                h="300px"
                overflow="auto"
              >
                <Text mb={4}>الاسم: {product?.name}</Text>
                <Flex mb={4}>
                  الكود: {product?.barcode}
                  <CopyToClipboard
                    text={product.barcode}
                    onCopy={() => {
                      toast({
                        position: "top",
                        description: "تم نسخ كود المنتج",
                      });
                    }}
                  >
                    <Box color="#57d2f4" ms={2}>
                      اضغط للنسخ
                    </Box>
                  </CopyToClipboard>
                </Flex>
                <Text mb={4} fontWeight="bold" color={theme.error}>
                  <Box display="inline" color={theme.blue}>
                    ملاحظة:{" "}
                  </Box>
                  {product?.note}
                </Text>
                <Text mb={4}>الوصف: {product?.description}</Text>
                {auth.user.role === "merchant" ? (
                  <Text mb={4}>سعر التكلفة: {product?.purchase_price} EGP</Text>
                ) : (
                  <Text mb={4}>سعر التكلفة: {product?.sale_price} EGP</Text>
                )}
                <Link
                  href={product?.media_url}
                  bg={theme.blue}
                  display="block"
                  w="100%"
                  p=".3rem 1rem"
                  borderRadius="5px"
                  mt={3}
                  color={theme.light}
                  textAlign="center"
                >
                  {t("pages.products.media_url")}
                </Link>
              </Box>
            </Box>
          </SimpleGrid>
          {product?.discount_rules?.length && (
            <>
              <Divider mt={8} borderColor="gray.300" />
              <Text fontSize="2xl" fontWeight="semibold" mt={6} mb={4}>
                العروض والخصومات
              </Text>
              <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={6}>
                {product?.discount_rules?.map((rule) => (
                  <Flex
                    key={rule._id}
                    borderWidth="1px"
                    borderRadius="lg"
                    p={6}
                    bg="white"
                    boxShadow="md"
                    _hover={{ boxShadow: "lg", transform: "scale(1.02)" }}
                    transition="all 0.2s ease-in-out"
                    align="center"
                  >
                    <Icon as={FaTag} boxSize={7} color="orange.500" ml={4} />
                    <Box>
                      <Text fontWeight="bold" fontSize="lg" color="gray.700">
                        {rule.name}
                      </Text>
                      <Text color="gray.500">الكمية: {rule.qty}</Text>
                      <Text color="gray.500">
                        سعر التكلفة للقطعة الواحدة: EGP {rule.sale_price}
                      </Text>
                      {/* <Text color="gray.500">
                    سعر الشراء: EGP {rule.purchase_price}
                  </Text> */}
                      <Text color="green.500" fontWeight="semibold">
                        خصم في القطعة: {rule.discount} EGP
                      </Text>
                    </Box>
                  </Flex>
                ))}
              </SimpleGrid>
            </>
          )}

          <Divider mt={6} borderColor={theme.gary1} />
          <Text mt={6} fontSize="23px" mb={6}>
            الخواص
          </Text>
          {(auth.user.role === "admin" ||
            (auth.user.role === "merchant" &&
              auth.user._id === product?.merchant?._id)) &&
            (product?.percent > 0 ? (
              <Flex justifyContent="center" mb={5}>
                <Box
                  width="fit-content"
                  bg="#4b7d06"
                  color={theme.light}
                  fontSize="22px"
                  paddingBlock={2}
                  paddingInline={4}
                  borderRadius="5px"
                  textAlign="center"
                >
                  <Box>نسبة تسليم المنتج</Box>
                  {`${product.percent?.toFixed(2) ?? 0} %`}
                </Box>
              </Flex>
            ) : (
              <Flex justifyContent="center" mb={5}>
                <Box
                  width="fit-content"
                  bg="#4b7d06"
                  color={theme.light}
                  fontSize="22px"
                  paddingBlock={2}
                  paddingInline={4}
                  borderRadius="5px"
                  textAlign="center"
                >
                  لم يتم تسليم اي طلبات حتى الأن
                </Box>
              </Flex>
            ))}

          <Box bg={theme.light} overflow="auto" p={5}>
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>الخاصية</Th>
                  {auth.user._id != product?.merchant?._id && <Th>الكمية</Th>}

                  {(auth.user.role === "admin" ||
                    (auth.user.role === "merchant" &&
                      auth.user._id === product?.merchant?._id)) && (
                    <>
                      <Th>المخزون</Th>

                      <Th>معلق</Th>
                      <Th>جار التحضير</Th>
                      <Th>تم الشحن</Th>
                      <Th>تم التاجيل</Th>
                      <Th>طلب العميل الإسترجاع</Th>
                      <Th>طلبات جار الإسترجاع</Th>
                      {/* <Th>مرتجعة بعد التسليم</Th> */}
                      <Th>تم التسليم</Th>
                      <Th>تم التحصيل</Th>
                    </>
                  )}
                  {(auth.user.role == "marketer" ||
                    auth.user.role == "moderator") && <Th>السلة</Th>}
                  {(auth.user?.rule?.name === "superadmin" ||
                    (auth.user.role === "merchant" &&
                      auth.user._id === product?.merchant?._id)) && (
                    <Th>سجل التغييرات</Th>
                  )}
                  {auth.user.role === "marketer" && <Th>كود الخاصية</Th>}
                </Tr>
              </Thead>
              <Tbody>
                {product?.properties.map((prop, index) => (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{prop.key}</Td>
                    {auth.user._id != product?.merchant?._id && (
                      <Td>{prop.value}</Td>
                    )}

                    {(auth.user.role === "admin" ||
                      (auth.user.role === "merchant" &&
                        auth.user._id === product?.merchant?._id)) && (
                      <>
                        <Td
                          textAlign="center"
                          style={
                            Number(prop?.real_stock || 0) <
                            Number(prop.pendingOrders) +
                              Number(prop.preparingOrders)
                              ? {
                                  backgroundColor: "#ef4444",
                                  color: "white",
                                  fontWeight: "bold",
                                }
                              : {}
                          }
                        >
                          {prop?.real_stock || 0}
                        </Td>
                        <Td>{prop.pendingOrders}</Td>
                        <Td>{prop.preparingOrders}</Td>
                        <Td>{prop.shippedOrders}</Td>
                        <Td>{prop.holdingOrders}</Td>
                        <Td>{prop.asToReturnOrders}</Td>
                        <Td>{prop.skipOrders}</Td>
                        {/* <Td>{prop.returned2Orders}</Td> */}
                        <Td>{prop.availableOrders}</Td>
                        <Td>{prop.collectedOrders}</Td>
                      </>
                    )}
                    {(auth.user.role == "marketer" ||
                      auth.user.role == "moderator") && (
                      <Td>
                        <Button
                          type="button"
                          bg={theme.success}
                          ms={2}
                          paddingBlock={2}
                          borderRadius="5px"
                          textAlign="center"
                          color={theme.light}
                          mt={6}
                          textTransform="capitalize"
                          onClick={() => {
                            const checkIfItemExists = cart.items.find(
                              (item) =>
                                item.product?._id === product._id &&
                                item.property?._id === prop._id
                            );
                            if (checkIfItemExists)
                              return toast({
                                description: "تم اضافة العنصر للسلة",
                                status: "error",
                              });
                            dispatch(
                              addToCart({
                                product: {
                                  _id: product._id,
                                  name: product.name,
                                  sale_price: product.sale_price,
                                  properties: product.properties,
                                  discount_rules: product?.discount_rules || [],
                                },
                                property: prop,
                                qty: 1,
                              })
                            );
                            toast({
                              description: "تم اضافة المنتج للسلة بنجاح.",
                            });
                          }}
                        >
                          <FiIcons.FiShoppingCart size={20} />
                        </Button>
                      </Td>
                    )}
                    {(auth.user?.rule?.name === "superadmin" ||
                      (auth.user.role === "merchant" &&
                        auth.user._id === product?.merchant?._id)) && (
                      <Td>
                        <Button
                          onClick={() => showLogs(prop._id)}
                          bg={theme.primary}
                          color={theme.light}
                        >
                          <MdHistory />
                        </Button>
                      </Td>
                    )}
                    {auth.user.role === "marketer" && (
                      <Td>
                        <CopyToClipboard
                          text={prop._id}
                          onCopy={() => {
                            toast({
                              position: "top",
                              description: "تم نسخ كود الخاصية",
                            });
                          }}
                        >
                          <Box color="#57d2f4" ms={2}>
                            اضغط للنسخ
                          </Box>
                        </CopyToClipboard>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          {showLogsModal && (
            <ProductLogs
              isOpen={showLogsModal}
              onClose={() => setShowLogsModal(false)}
            />
          )}
        </>
      )}
    </ProductsWrapper>
  );
};

export default ProductDetails;
