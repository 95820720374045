import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  SimpleGrid,
  Box,
  Text,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "@chakra-ui/react";

import Filter from "./actions/Filter";

import * as FiIcons from "react-icons/fi";
import * as AiIcons from "react-icons/ai";
import * as MdIcons from "react-icons/md";
import * as BiIcons from "react-icons/bi";
import { getStatistics } from "../../../store/dashboard/statisticsSlice";

import theme from "../../global/theme";

import { DashboardWrapper } from "./DashboardStyle";
import { useNavigate } from "react-router-dom";
import formatNumber from "../../../utilities/formatNumber";
import MostPopularProducts from "./MostPopularProducts";

const Dashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const statistics = useSelector((state) => state.statistics);
  const auth = useSelector((state) => state.auth);

  const [filter, setFilter] = useState({});

  useEffect(() => {
    dispatch(getStatistics({ filter: JSON.stringify(filter) }));
  }, [dispatch, filter]);

  return (
    <DashboardWrapper>
      {/* {auth?.user?.role !== "admin" && (
        <Alert bg={theme.primary} color={theme.light} mb={4} fontSize={22}>
          <>
            لا يمكنك إضافة طلب جديد في الوقت الحالي ، يرجى الإنتظار بعد أجازة
            عيد الأضحي المبارك 🐑 وانتظرونا بالتحديث الجديد 🔥
          </>
        </Alert>
      )} */}
      <Flex paddingBlock={8} justifyContent="space-between" alignItems="center">
        <Text
          fontWeight="bold"
          textTransform="capitalize"
          fontSize={{ base: 14, lg: 16 }}
          color={theme.dark}
        >
          التقارير العامة
        </Text>
        <Filter
          isLoading={statistics.isLoading}
          handleFilter={(values) => setFilter(values)}
          handleClearFilter={() => setFilter({})}
        />
      </Flex>

      {((auth.user.role === "admin" &&
        auth.user?.rule?.permissions.includes("manage_dashboard")) ||
        auth.user.role === "moderator" ||
        auth.user.role === "marketer" ||
        auth.user.role === "merchant") && (
        <SimpleGrid columns={{ base: 2, md: 2, lg: 3, xl: 4 }} spacing={4}>
          <Flex
            boxShadow={theme.shadow}
            bg={theme.light}
            padding={6}
            alignItems="center"
            justifyContent="space-between"
            borderRadius="md"
          >
            <Box color={theme.primary}>
              <MdIcons.MdToday size={40} />
            </Box>
            <Box ml={4}>
              <Text
                fontWeight="bold"
                fontSize={{ base: 20, lg: 25 }}
                color={theme.dark}
              >
                {statistics.data?.ordersOfToday}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 14, lg: 16 }}
                color={theme.dark}
              >
                طلبات اليوم
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={theme.shadow}
            bg={theme.light}
            padding={6}
            alignItems="center"
            justifyContent="space-between"
            borderRadius="md"
          >
            <Box color={theme.primary}>
              <MdIcons.MdCalendarToday size={40} />
            </Box>
            <Box ml={4}>
              <Text
                fontWeight="bold"
                fontSize={{ base: 20, lg: 25 }}
                color={theme.dark}
              >
                {statistics.data?.ordersOfWeek}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 14, lg: 16 }}
                color={theme.dark}
              >
                طلبات هذا الاسبوع
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={theme.shadow}
            bg={theme.light}
            padding={6}
            alignItems="center"
            justifyContent="space-between"
            borderRadius="md"
          >
            <Box color={theme.primary}>
              <MdIcons.MdCalendarMonth size={40} />
            </Box>
            <Box ml={4}>
              <Text
                fontWeight="bold"
                fontSize={{ base: 20, lg: 25 }}
                color={theme.dark}
              >
                {statistics.data?.ordersOfMonth}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 14, lg: 16 }}
                color={theme.dark}
              >
                طلبات هذا الشهر
              </Text>
            </Box>
          </Flex>

          <Flex
            boxShadow={theme.shadow}
            bg={theme.light}
            padding={6}
            alignItems="center"
            justifyContent="space-between"
            borderRadius="md"
          >
            <Box color={theme.primary}>
              <BiIcons.BiBox size={40} />
            </Box>
            <Box ml={4}>
              <Text
                fontWeight="bold"
                fontSize={{ base: 20, lg: 25 }}
                color={theme.dark}
              >
                {formatNumber(statistics.data?.totalOrders)}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 14, lg: 16 }}
                color={theme.dark}
              >
                اجمالي الطلبات
              </Text>
            </Box>
          </Flex>

          {auth.user.role !== "moderator" && (
            <Flex
              boxShadow={theme.shadow}
              bg={theme.light}
              padding={6}
              alignItems="center"
              justifyContent="space-between"
              borderRadius="md"
            >
              <Box color={theme.success}>
                <FiIcons.FiDollarSign size={40} />
              </Box>
              <Box ml={4}>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 20, lg: 25 }}
                  color={theme.dark}
                >
                  {(statistics.data?.totalDoneRequests &&
                    formatNumber(
                      statistics.data?.totalDoneRequests[0]?.amount
                    )) ??
                    0}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 14, lg: 16 }}
                  color={theme.dark}
                >
                  ارباح تم سحبها
                </Text>
              </Box>
            </Flex>
          )}

          <Flex
            boxShadow={theme.shadow}
            bg={theme.light}
            padding={6}
            alignItems="center"
            justifyContent="space-between"
            borderRadius="md"
          >
            <Box color={theme.success}>
              <FiIcons.FiDollarSign size={40} />
            </Box>
            <Box ml={4}>
              <Text
                fontWeight="bold"
                fontSize={{ base: 20, lg: 25 }}
                color={theme.dark}
              >
                {(statistics.data?.totalSales &&
                  formatNumber(statistics.data?.totalSales[0]?.total)) ??
                  0}
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 14, lg: 16 }}
                color={theme.dark}
              >
                {auth.user.role !== "merchant"
                  ? "اجمالي المبيعات"
                  : "اجمالي الارباح"}
              </Text>
            </Box>
          </Flex>

          {auth.user.role !== "moderator" && auth.user.role !== "merchant" && (
            <Flex
              boxShadow={theme.shadow}
              bg={theme.light}
              padding={6}
              alignItems="center"
              justifyContent="space-between"
              borderRadius="md"
            >
              <Box color={theme.success}>
                <FiIcons.FiDollarSign size={40} />
              </Box>
              <Box ml={4}>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 20, lg: 25 }}
                  color={theme.dark}
                >
                  {(statistics.data?.totalIncome &&
                    formatNumber(
                      statistics.data?.totalIncome[0]?.commission
                    )) ??
                    0}
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 14, lg: 16 }}
                  color={theme.dark}
                >
                  اجمالي الارباح
                </Text>
              </Box>
            </Flex>
          )}

          <Flex
            boxShadow={theme.shadow}
            bg={theme.success}
            padding={6}
            alignItems="center"
            justifyContent="space-between"
            borderRadius="md"
          >
            <Box color={theme.light}>
              <AiIcons.AiOutlinePercentage size={40} />
            </Box>
            <Box ml={4}>
              <Text
                fontWeight="bold"
                fontSize={{ base: 20, lg: 25 }}
                color={theme.light}
              >
                <span style={{ color: theme.light }}>
                  {statistics.data?.percent?.toFixed(2) ?? "-"}
                </span>
              </Text>
              <Text
                fontWeight="bold"
                fontSize={{ base: 14, lg: 16 }}
                color={theme.light}
              >
                نسبة التسليم
              </Text>
            </Box>
          </Flex>

          {(auth.user.role === "admin" || auth.user.role === "marketer") && (
            <Flex
              boxShadow={theme.shadow}
              bg={theme.success}
              padding={6}
              alignItems="center"
              justifyContent="space-between"
              borderRadius="md"
            >
              <Box color={theme.light}>
                <AiIcons.AiOutlineStar size={40} />
              </Box>
              <Box ml={4}>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 20, lg: 25 }}
                  color={theme.light}
                >
                  <span style={{ color: theme.light }}>
                    {statistics.data?.callCenterQuality?.toFixed(2) ?? "-"}
                  </span>
                </Text>
                <Text
                  fontWeight="bold"
                  fontSize={{ base: 14, lg: 16 }}
                  color={theme.light}
                >
                  جودة العملاء ونسبة التأكيد
                </Text>
              </Box>
            </Flex>
          )}
        </SimpleGrid>
      )}
      {/* {auth.user?.role === "marketer" && (
        <SimpleGrid columns={{ lg: 2, xl: 3 }} spacing={{ lg: 6 }} mt={8}>
          <Box p={4} boxShadow="md" bg="white" borderRadius="md">
            <Text
              fontWeight="bold"
              textTransform="capitalize"
              fontSize={{ base: "md", lg: "lg" }}
              color={"#2d3748"}
              mb={4}
            >
              المنتجات الأكثر مبيعاً
            </Text>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>اسم المنتج</Th>
                </Tr>
              </Thead>
              <Tbody>
                {statistics.data?.mostPopular?.map((el, index) => (
                  <Tr
                    key={index}
                    cursor="pointer"
                    onClick={() => navigate(`available_products/${el?._id}`)}
                    _hover={{ bg: "#f7fafc" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td>{el?.name}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
          <Box p={4} boxShadow="md" bg="white" borderRadius="md">
            <Text
              fontWeight="bold"
              textTransform="capitalize"
              fontSize={{ base: "md", lg: "lg" }}
              color={"#2d3748"}
              mb={4}
            >
              المنتجات المضاف حديثاً
            </Text>
            <Table variant="striped" colorScheme="gray">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>اسم المنتج</Th>
                </Tr>
              </Thead>
              <Tbody>
                {statistics.data?.mostRecent?.map((el, index) => (
                  <Tr
                    key={index}
                    cursor="pointer"
                    onClick={() => navigate(`available_products/${el?._id}`)}
                    _hover={{ bg: "#f7fafc" }}
                  >
                    <Td>{index + 1}</Td>
                    <Td>{el?.name}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </SimpleGrid>
      )} */}
      {auth.user?.role === "admin" &&
        auth.user?.rule?.permissions.includes("manage_dashboard") && (
          <Box>
            <Flex
              paddingBlock={8}
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                fontWeight="bold"
                textTransform="capitalize"
                fontSize={{ base: 14, lg: 16 }}
                color={theme.dark}
              >
                {t("general.details")}
              </Text>
            </Flex>

            <SimpleGrid
              columns={{ base: 2, lg: 3, xl: 4 }}
              //minChildWidth="180px"
              spacing={6}
            >
              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiShoppingCart size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 25, lg: 30 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.totalProducts}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 14, lg: 16 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المنتجات
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiShoppingCart size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 25, lg: 30 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.activeProducts}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 14, lg: 16 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المنتجات النشطه
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiShoppingCart size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 25, lg: 30 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.disActiveProducts}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 14, lg: 16 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المنتجات الغير نشطة
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiUsers size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 25, lg: 30 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.users}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 14, lg: 16 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المستخدمين
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiUsers size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 25, lg: 30 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.merchants}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 14, lg: 16 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    التجار
                  </Text>
                </Box>
              </Flex>

              <Flex
                boxShadow={theme.shadow}
                bg={theme.light}
                padding={6}
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex justifyContent="space-between">
                  <Box color="#F78B00">
                    <FiIcons.FiUsers size={40} />
                  </Box>
                </Flex>
                <Box>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 25, lg: 30 }}
                    mt={4}
                    color={theme.dark}
                  >
                    {statistics.data?.marketers}
                  </Text>
                  <Text
                    fontWeight="bold"
                    fontSize={{ base: 14, lg: 16 }}
                    textTransform="capitalize"
                    color={theme.dark}
                  >
                    المسوقين
                  </Text>
                </Box>
              </Flex>
            </SimpleGrid>
          </Box>
        )}

      {auth.user?.role === "marketer" && <MostPopularProducts />}
    </DashboardWrapper>
  );
};

export default Dashboard;
