import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Badge,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Box,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Flex,
} from "@chakra-ui/react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { getProductLogs } from "../../../../store/products/productsSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Pagination from "../../../shared/pagination/Pagination";

const ProductLogs = ({ isOpen, onClose }) => {
  const { productLogs } = useSelector((state) => state.products);
  const { productId } = useParams();

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState("");
  const [filter, setFilter] = useState(JSON.stringify({}));

  const dispatch = useDispatch();
  useEffect(() => {
    const filterQ = {
      "property._id": isOpen,
      ...(category ? { category } : {}),
    };
    setFilter(JSON.stringify(filterQ));
  }, [isOpen, category]);
  useEffect(() => {
    dispatch(getProductLogs({ productId, page, size, query, filter }));
  }, [productId, page, size, query, filter]);
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay />
        <ModalContent
          borderRadius="md"
          boxShadow="lg"
          p={{ base: 2, md: 4 }} // Adjust padding for responsiveness
        >
          <ModalHeader
            textAlign="center"
            fontSize={{ base: "lg", md: "xl" }} // Adjust header font size for smaller screens
            fontWeight="semibold"
            color="teal.600"
          >
            سجل التغييرات
          </ModalHeader>
          <Flex justifyContent="center" alignItems="center" gap={4}>
            <Button
              bg={category === "" ? "gray.600" : "gray.500"}
              color="white"
              _hover={{ bg: "gray.700" }}
              onClick={() => setCategory("")}
            >
              الكل
            </Button>
            <Button
              bg={category === "orders" ? "gray.600" : "gray.500"}
              color="white"
              _hover={{ bg: "gray.700" }}
              onClick={() => setCategory("orders")}
            >
              طلبات
            </Button>
            <Button
              bg={category === "quantities" ? "gray.600" : "gray.500"}
              color="white"
              _hover={{ bg: "gray.700" }}
              onClick={() => setCategory("quantities")}
            >
              مخزون
            </Button>
          </Flex>
          <ModalCloseButton />
          <ModalBody>
            {productLogs?.data?.length === 0 ? (
              <Box textAlign="center" color="gray.500">
                <Text>لا توجد سجلات لهذه الخاصية</Text>
              </Box>
            ) : (
              <Box overflowX="auto">
                <Table
                  variant="striped"
                  mt={8}
                  colorScheme="gray"
                  dir="rtl"
                  width="100%" // Ensure the table is full-width
                >
                  <Thead>
                    <Tr>
                      <Th fontSize={{ base: "sm", md: "md" }}>تاريخ التغيير</Th>
                      <Th fontSize={{ base: "sm", md: "md" }}>الخاصية</Th>
                      <Th fontSize={{ base: "sm", md: "md" }}>
                        القيمة القديمة
                      </Th>
                      <Th fontSize={{ base: "sm", md: "md" }}>
                        القيمة الجديدة
                      </Th>
                      <Th fontSize={{ base: "sm", md: "md" }}>الفرق</Th>
                      <Th fontSize={{ base: "sm", md: "md" }}>السبب</Th>
                      <Th fontSize={{ base: "sm", md: "md" }}>
                        تم التغيير بواسطة
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {productLogs?.data?.map((log, index) => {
                      const { oldValue, newValue } = log.property;
                      let changeIndicator = null;
                      let changeColor = "gray";

                      // Determine the change direction
                      if (newValue > oldValue) {
                        changeIndicator = <FiArrowUp color="green" />;
                        changeColor = "green";
                      } else if (newValue < oldValue) {
                        changeIndicator = <FiArrowDown color="red" />;
                        changeColor = "red";
                      }

                      return (
                        <Tr key={log._id}>
                          <Td>{new Date(log.createdAt).toLocaleString()}</Td>
                          <Td>{log.property.key}</Td>
                          <Td>{oldValue}</Td>
                          <Td>
                            {newValue} {changeIndicator}
                          </Td>
                          <Td>
                            {newValue - oldValue} {changeIndicator}
                          </Td>
                          <Td>
                            <Badge colorScheme={changeColor}>
                              {log.reason}
                            </Badge>
                          </Td>
                          <Td>{log.changedBy.name}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <Flex justify="between" align="center" mt={4}>
              <Pagination
                page={page}
                itemsCount={productLogs?.itemsCount ?? 0}
                onChange={(page) => setPage(page)}
              />
            </Flex>
            <Stack direction="row" spacing={4} justify="center" w="full">
              <Button
                variant="outline"
                colorScheme="teal"
                onClick={onClose}
                w={{ base: "full", sm: "auto" }} // Full width on small screens, auto width on larger screens
              >
                إغلاق
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ProductLogs;
