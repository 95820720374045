import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Flex,
  Button,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Text,
  Spinner,
  Box,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { FaEye } from "react-icons/fa";
import CreateModal from "./actions/CreateModal.jsx";
import { useSelector, useDispatch } from "react-redux";
import { getAdminAlerts, getAlerts } from "../../../store/alerts/alertSlice.js";
import Pagination from "../../shared/pagination/Pagination";

const Announcements = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showCreateAlert, setShowCreateAlert] = useState(false);
  const [viewAlert, setViewAlert] = useState(null); // State for the alert to view
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alerts || []);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getAdminAlerts({ page }));
  }, [dispatch, page, showCreateAlert]);

  const handleViewClick = (alert) => {
    setViewAlert(alert);
    onOpen();
  };

  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6} mb={8}>
        <Flex>
          <Button
            colorScheme="red"
            fontSize="lg"
            fontWeight="bold"
            onClick={() => setShowCreateAlert(true)}
          >
            {t("إرسال تنبيه")}
          </Button>
        </Flex>
      </SimpleGrid>

      {showCreateAlert && (
        <CreateModal onClose={() => setShowCreateAlert(false)} />
      )}

      {alerts?.isLoading ? (
        <Flex justify="center" align="center" mt={12}>
          <Spinner size="xl" color="red.500" />
        </Flex>
      ) : (
        alerts?.adminAlerts?.data?.length > 0 && (
          <Box overflowX="auto">
            <Table variant="striped" mt={8} colorScheme="gray" dir="rtl">
              <Thead bg="gray.100">
                <Tr>
                  <Th textAlign="center">{t("الوصف")}</Th>
                  <Th textAlign="center" isNumeric>
                    {t("إجمالي المستخدمين")}
                  </Th>
                  <Th textAlign="center" isNumeric>
                    {t("الذين قرأوا")}
                  </Th>
                  <Th textAlign="center" isNumeric>
                    {t("الذين لم يقرأوا")}
                  </Th>
                  <Th textAlign="center">{t("تاريخ الإنشاء")}</Th>
                  <Th textAlign="center">{t("عرض التفاصيل")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {alerts?.adminAlerts?.data.map((alert) => (
                  <Tr key={alert._id}>
                    <Td textAlign="center" style={{ textAlign: "center" }}>
                      {alert.alertDetails.description.slice(0, 50)}...
                    </Td>
                    <Td
                      textAlign="center"
                      style={{ textAlign: "center" }}
                      isNumeric
                    >
                      {alert.totalUsers}
                    </Td>
                    <Td
                      textAlign="center"
                      style={{ textAlign: "center" }}
                      isNumeric
                    >
                      {alert.readUsers}
                    </Td>
                    <Td
                      textAlign="center"
                      style={{ textAlign: "center" }}
                      isNumeric
                    >
                      {alert.unreadUsers}
                    </Td>
                    <Td textAlign="center" style={{ textAlign: "center" }}>
                      {new Date(
                        alert.alertDetails.created_at
                      ).toLocaleDateString("ar-EG")}
                    </Td>
                    <Td textAlign="center" style={{ textAlign: "center" }}>
                      <IconButton
                        icon={<FaEye />}
                        onClick={() => handleViewClick(alert)}
                        variant="outline"
                        colorScheme="blue"
                        fontSize="20px"
                        aria-label={t("عرض التنبيه")}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        )
      )}

      <Flex justify="flex-end" align="center" mt={4}>
        <Pagination
          page={page}
          itemsCount={alerts.adminAlerts?.itemsCount ?? 0}
          onChange={(page) => setPage(page)}
        />
      </Flex>

      {/* View Modal */}
      {viewAlert && (
        <Modal isOpen={isOpen} onClose={onClose} size="lg">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t("تفاصيل التنبيه")}</ModalHeader>

            <ModalBody>
              <Text fontWeight="bold">{t("الوصف")}: </Text>
              <Text mb={4}>{viewAlert.alertDetails.description}</Text>

              <Text fontWeight="bold">{t("الرابط")}: </Text>
              <Text mb={4}>{viewAlert.alertDetails.url}</Text>

              <Text fontWeight="bold">{t("فئة التنبيه")}: </Text>
              <Text mb={4}>{viewAlert.alertDetails.category}</Text>

              <Text fontWeight="bold">{t("تاريخ الإنشاء")}: </Text>
              <Text mb={4}>
                {new Date(viewAlert.alertDetails.created_at).toLocaleDateString(
                  "ar-EG"
                )}
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={onClose}>
                {t("إغلاق")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default Announcements;
